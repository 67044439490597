// feature
import { graphql } from 'gatsby'
import Link, { navigate } from 'gatsby-link'
import styled, { css } from 'styled-components'
import { connect } from 'react-redux'
import { mapStateToProps, mapDispatchToProps } from '@/store/index'
import { breakpointDown, breakpointUp } from '@/utils/breakpoints'
import { sortedPages } from '@/feature/sortedPages'
import { metaData } from '@/feature/staticData'
import { colorConfig, mixins, zIndex } from '@/utils/styleConfig'
import { Seo, LayoutContainer } from '@/utils/globalComponents'

// interface
import { ChangeEvent } from 'react'

// components
import Layout from '@/components/Layout'
import { HeadingLevel1 } from '@/components/HeadingLevel1'
import { HeadingLevel2 } from '@/components/HeadingLevel2'
import { CaseCard } from '@/components/CaseCard'
import { InputButton } from '@/components/InputButton'
import { BreakPointHiddenBr, TextThemeColor } from '@/components/Utils'

export const query = graphql`
  {
    allMdx(
      filter: { frontmatter: { date: { ne: null } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      casePages: edges {
        casePage: node {
          id
          slug
          frontmatter {
            cardSize
            caseTags
            caseType
            title
            date(formatString: "YYYY.MM.DD")
          }
        }
      }
    }
  }
`

const IndexPage = props => {
  const { casePages } = props.data.allMdx
  const { sortedPagesByCardSize } = sortedPages(casePages)

  const selectedLargeImageCase = sortedPagesByCardSize.filter(
    item => item.casePage.frontmatter.cardSize === 'large',
  )

  // OPTIMIZE: 最大表示数の判定をもう少しすっきりさせたい
  let caseCount = 0

  const isShowCase = (articleCaseTags: string[]): boolean => {
    const selectedLargeImageCaseLength = selectedLargeImageCase.length

    // NOTE: 最大表示数を 4 とする
    if (caseCount >= 4) {
      return false
    }

    for (let index = 0; index < selectedLargeImageCaseLength; index++) {
      const hasTag = articleCaseTags.includes('ディスプレイ事業部')

      if (!hasTag) {
        return false
      }
    }

    caseCount++
    return true
  }

  const routingSelectedCaseTags = (event: ChangeEvent<HTMLInputElement>): void => {
    const isChecked: boolean = event.currentTarget.checked

    props.clearCaseFilter()

    if (isChecked) {
      props.addCaseFilter(event)
    }

    navigate('/case/')
  }

  return (
    <Layout>
      <Seo page="display" />
      <Display>
        <LayoutContainer>
          <HeadingLevel1 note="display division">
            ディスプレイ
            <BreakPointHiddenBr direction="up" breakpoint="md" />
            事業部
          </HeadingLevel1>
          <Lead>
            <HeadingLevel2>
              <TextThemeColor>
                独自の技術と
                <BreakPointHiddenBr direction="up" breakpoint="md" />
                ノウハウを生かして
                <br />
                オフィスの省力化を実現
              </TextThemeColor>
            </HeadingLevel2>
            <LeadText>
              メカトロニクスとアートを融合させた
              <BreakPointHiddenBr direction="down" breakpoint="sm" />
              メカトロニクス・ディスプレイの技術を駆使し、
              <BreakPointHiddenBr direction="down" breakpoint="sm" />
              企画からメンテナンスまで一気通貫でお応えして、
              <BreakPointHiddenBr direction="down" breakpoint="sm" />
              科学館・博物館・テーマパーク・企業SR・商業施設等多岐にわたり
              <BreakPointHiddenBr direction="down" breakpoint="sm" />
              実績をつくって参りました。
              <br />
              創業130年以上の豊富な経験と信頼・蓄積した技術を強みとして、
              <BreakPointHiddenBr direction="down" breakpoint="sm" />
              既存事業のみならず、新しくデジタル技術も取り入れて、
              <BreakPointHiddenBr direction="down" breakpoint="sm" />
              夢でもあるオリジナル製品の開発にも挑戦し、
              <BreakPointHiddenBr direction="down" breakpoint="sm" />
              お客様に『楽』（楽しさ）を提供して
              <BreakPointHiddenBr direction="down" breakpoint="sm" />
              心豊かになれる世の中に貢献していきたいと思います。
            </LeadText>
          </Lead>

          <Flow>
            <HeadingLevel2 note="flow">製品が出来るまでの流れ</HeadingLevel2>
            <FlowOrderedList>
              <li>
                <FlowIcon>
                  <img
                    src="/images/service/icon_speech-bubble.svg"
                    alt=""
                    width="128"
                    height="128"
                  />
                </FlowIcon>
                <FlowButton>
                  <Link to={`${metaData['display-detail'].url}#flow-1`}>
                    企画 / <br />
                    立案・設計
                  </Link>
                </FlowButton>
              </li>
              <li>
                <FlowIcon>
                  <img src="/images/service/icon_hearing.svg" alt="" width="128" height="128" />
                </FlowIcon>
                <FlowButton>
                  <Link to={`${metaData['display-detail'].url}#flow-2`}>
                    造作・什器・
                    <BreakPointHiddenBr direction="up" breakpoint="md" />
                    造形・
                    <BreakPointHiddenBr direction="down" breakpoint="sm" />
                    模型 / <BreakPointHiddenBr direction="up" breakpoint="md" />
                    設計・製作
                  </Link>
                </FlowButton>
              </li>
              <li>
                <FlowIcon>
                  <img src="/images/service/icon_monitor.svg" alt="" width="128" height="128" />
                </FlowIcon>
                <FlowButton>
                  <Link to={`${metaData['display-detail'].url}#flow-3`}>
                    メカ / <br />
                    設計・製作
                  </Link>
                </FlowButton>
              </li>
              <li>
                <FlowIcon>
                  <img
                    src="/images/service/icon_screw-driver.svg"
                    alt=""
                    width="128"
                    height="128"
                  />
                </FlowIcon>
                <FlowButton>
                  <Link to={`${metaData['display-detail'].url}#flow-4`}>
                    制御ソフト / <br />
                    設計・製作
                  </Link>
                </FlowButton>
              </li>
              <li>
                <FlowIcon>
                  <img src="/images/service/icon_wrench.svg" alt="" width="128" height="128" />
                </FlowIcon>
                <FlowButton>
                  <Link to={`${metaData['display-detail'].url}#flow-5`}>
                    施工・
                    <BreakPointHiddenBr direction="up" breakpoint="md" />
                    メンテナンス
                  </Link>
                </FlowButton>
              </li>
            </FlowOrderedList>
          </Flow>
        </LayoutContainer>
        <LayoutContainer bgColor="attention">
          <Value>
            <HeadingLevel2 note="value">弊社の魅力</HeadingLevel2>
            <ValueList>
              <li>
                <ValueImg>
                  <img src="/images/service/display/img_01.jpg" alt="" width="230" height="156" />
                </ValueImg>
                <ValueHeading>オリジナル</ValueHeading>
                <p>オリジナル製品（一品物）も開発・製作します。</p>
              </li>
              <li>
                <ValueImg>
                  <img src="/images/service/display/img_02.jpg" alt="" width="230" height="156" />
                </ValueImg>
                <ValueHeading>提案力</ValueHeading>
                <p>お客様が本当に必要としている製品を提案します。</p>
              </li>
              <li>
                <ValueImg>
                  <img src="/images/service/display/img_03.jpg" alt="" width="230" height="156" />
                </ValueImg>
                <ValueHeading>高品質</ValueHeading>
                <p>製品完成までの工程を一貫して行うことで、より早く品質の高い製品を提供します。</p>
              </li>
              <li>
                <ValueImg>
                  <img src="/images/service/display/img_04.jpg" alt="" width="230" height="156" />
                </ValueImg>
                <ValueHeading>サポート</ValueHeading>
                <p>技術者が設置・使い方までサポートしますので、納入後の対応も万全です。</p>
              </li>
            </ValueList>
          </Value>
        </LayoutContainer>
        <LayoutContainer>
          <Case>
            <HeadingLevel2 note="works">ディスプレイ事業部の事例</HeadingLevel2>
            <CaseCardList>
              {selectedLargeImageCase.map(({ casePage }) => {
                return (
                  isShowCase(casePage.frontmatter.caseTags) && (
                    <CaseCardListItem cardSize={casePage.frontmatter.cardSize} key={casePage.id}>
                      <CaseCard
                        link={`/case/${casePage.slug}/`}
                        heading={casePage.frontmatter.title}
                        imgSrc={`/images/case/${casePage.slug}/1.jpg`}
                        caseType={casePage.frontmatter.caseType}
                        cardSize={casePage.frontmatter.cardSize}
                      />
                    </CaseCardListItem>
                  )
                )
              })}
            </CaseCardList>
            <InputButtonContainer>
              <InputButton
                onChange={event => routingSelectedCaseTags(event)}
                value={metaData.display.title}
                checked={props.selectedCaseTags.includes(metaData.display.title)}
                label={`${metaData.display.title}の事例はこちら`}
                color="theme"
              ></InputButton>
            </InputButtonContainer>
          </Case>
        </LayoutContainer>
      </Display>
    </Layout>
  )
}

const Display = styled.div`
  position: relative;

  ${breakpointUp('md')} {
    &::after {
      content: '';
      display: block;
      position: absolute;
      z-index: ${zIndex.background};
      top: -7rem;
      left: 0;
      right: 0;
      transform: translateX(38rem);
      margin: auto;
      width: 64rem;
      height: 64rem;
      background: top / 100% no-repeat url(/images/service/display/mv_img.png);
    }
  }
`

const Lead = styled.section`
  margin-top: 8rem;

  ${breakpointUp('md')} {
    margin-top: 6rem;
  }
`

const LeadText = styled.p`
  margin-top: 1.6rem;
  line-height: 1.8;
  letter-spacing: 0.04em;

  ${breakpointDown('sm')} {
    font-size: 1.4rem;
  }

  ${breakpointUp('md')} {
  }
`

const Flow = styled.section`
  margin-top: 8rem;
  margin-bottom: 10rem;

  ${breakpointUp('md')} {
    margin-top: 10rem;
    margin-bottom: 10rem;
  }
`

const FlowOrderedList = styled.ol`
  margin-top: 4rem;

  ${breakpointDown('sm')} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2.8rem 2.5rem;
  }

  ${breakpointUp('md')} {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 2.4rem;
  }

  > li {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 2.1rem;
      height: 1.3rem;
      background: top / 100% no-repeat url(/images/service/icon_arrow-r.svg);
    }

    ${breakpointDown('sm')} {
      &:nth-of-type(odd) {
        &::after {
          top: 0;
          right: -2.2rem;
          bottom: 0;
          margin: auto 0;
        }
      }

      &:nth-of-type(even) {
        &::after {
          left: -2.4rem;
          bottom: -2.6rem;
          transform: rotate(135deg);
        }
      }
    }

    ${breakpointUp('md')} {
      &::after {
        top: -4rem;
        right: -2.2rem;
        bottom: 0;
        margin: auto 0;
      }
    }

    &:last-of-type {
      &::after {
        display: none;
      }
    }
  }
`

const FlowIcon = styled.div`
  width: 12.8rem;
`

const FlowButton = styled.div`
  ${mixins.hoverButton()}
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 9999px;
  line-height: 1.5;
  padding: 1.3rem 1.2rem;
  width: 100%;
  font-size: 1.6rem;
  font-weight: bold;
  letter-spacing: 0.08em;
  background-color: ${colorConfig.themeColor};
  color: ${colorConfig.textSubColor};

  > a {
    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
`

const Value = styled.section`
  padding-top: 8rem;
  padding-bottom: 8rem;

  ${breakpointUp('md')} {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
`

const ValueList = styled.ul`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 4rem 2.5rem;
  margin-top: 4.8rem;

  ${breakpointUp('md')} {
    grid-template-columns: repeat(4, 1fr);
    gap: 4rem 3.5rem;
    margin-top: 3.8rem;
  }
`

const ValueImg = styled.div``

const ValueHeading = styled.p`
  margin-top: 1.6rem;
  margin-bottom: 0.8rem;
  font-size: 2rem;
  color: ${colorConfig.themeColor};
`

const Case = styled.section`
  margin: 8rem 0;

  ${breakpointUp('md')} {
    margin: 10rem 0;
  }
`

const CaseCardList = styled.ul`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.6rem;
  margin-top: 4rem;

  ${breakpointUp('md')} {
    grid-auto-flow: dense;
    grid-template-columns: repeat(4, 1fr);
    gap: ${mixins.vw({ size: 36 })} ${mixins.vw({ size: 24 })};
  }

  ${breakpointUp('lg')} {
    gap: 3.6rem 2.4rem;
  }
`

const CaseCardListItem = styled.li<{ cardSize: string }>`
  ${({ cardSize }) =>
    cardSize === 'large'
      ? css`
          grid-row: span 2;
          grid-column: span 2;
        `
      : css`
          width: 100%;
        `}
`

const InputButtonContainer = styled.div`
  margin-top: 3rem;
  text-align: center;
`

export default connect(mapStateToProps, mapDispatchToProps)(IndexPage)
